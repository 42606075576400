<template>
<div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
        <div class="row">
            
            <div class="col">

                <div class="card shadow">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <base-alert v-if="errorMessage" :type="errorType">
                                    {{errorMessage}}
                                </base-alert>
                            </div>


                            <div class="col">
                                <h3 class="mb-0">
                                    Member List
                                    <span v-if="this.$store.getters.StateUser.user.role!=='staff'">
                                        &nbsp;&nbsp;&nbsp;<a v-if="this.$store.getters.StateUser.user.role==='super-admin'" target="_blank" href="https://api.futurewash.com/api/member-csv-file" class="btn btn-info btn-sm">Export CSV</a>
                                        &nbsp;&nbsp;&nbsp;<router-link to="/import-member" class="btn btn-info btn-sm">Import CSV</router-link>
                                        &nbsp;&nbsp;&nbsp;<router-link to="/member-create" class="btn btn-outline-info btn-sm">Add Member</router-link>
                                        &nbsp;&nbsp;&nbsp;<router-link to="/archived-members" class="btn btn-outline-info btn-sm">Archived Members</router-link>
                                    </span>
                                </h3>
                            </div>
                            <div class="col text-right">
                                
                            <input placeholder="Search" v-model="search" v-on:keyup="searchFun" width="200px" />
                                <!--<base-button type="info" size="sm">See all</base-button>-->
                            </div>
                        </div>
                    </div>

                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" @sort-change="onSortChange" :data="members" style="width: 100%">
                        
                        <el-table-column label="Member #"  prop="name">
                            <template v-slot="{ row }">
                            {{row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Points" min-width="120px" sortable="custom">
                            <template v-slot="{ row }">
                            <div v-if="row.isAccountApproved==1 && row.status==1">
                                <span style="float:left;">
                                    <button class="btn btn-outline-info btn-sm" :disabled="row.point==0 || !row.status" @click="decrement(row.id)">-</button>
                                        {{(!row.point)?0:row.point}}
                                    <button class="btn btn-outline-info btn-sm" :disabled="!row.status" @click="increment(row.id)">+</button> &nbsp;&nbsp;&nbsp;
                                </span>
                                <span style="float:right;">
                                    <button v-if="row.point!=row.originalPoint" @click="saveNewPoint(row.id)" type="button" class="btn btn-info btn-sm">Save</button>
                                </span>
                            </div>
                            <div v-if="!row.isAccountApproved && !row.membershipCancelNote">    
                                <button v-if="!row.isAccountApproved" @click="approveAccount(row.id)" type="button" class="btn btn-info btn-sm">Approve</button>
                                
                            </div>
                            <div v-if="(row.isAccountApproved && !row.status) || (row.membershipCancelNote && !row.status)">    
                                {{(!row.point)?0:row.point}}
                            </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Point Member" >
                            <template v-slot="{ row }">
                            {{row.membershipPoint}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Full Name" >
                            <template v-slot="{ row }">
                            <span style="text-transform:capitalize;">{{row.firstName+' '+row.lastName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Email Address" min-width="100px">
                            <template v-slot="{ row }">
                            {{row.email}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Phone Number">
                            <template v-slot="{ row }">
                            {{row.contact}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Billable Amount" prop="budget">
                            <template v-slot="{ row }">
                            {{(row.billableAmount)?'$'+row.billableAmount:'-'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Date Joined">
                            <template v-slot="{ row }">
                            {{row.createdAt}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Wash At Home Subscriber">
                            <template v-slot="{ row }">
                            {{((row.washAtHome===null)?'-':(row.washAtHome)?'Yes':'No')}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Note">
                            <template v-slot="{ row }">
                            {{(row.note)?((row.note.length>30)?row.note.slice(0, 30)+'...':row.note):'-'}}
                            </template>
                        </el-table-column>

                        <el-table-column label="Status" prop="status">
                            <template v-slot="{ row }">
                                <badge class="badge-dot mr-4" type="light">
                                    <i :class="`bg-${(row.status)?'success':'danger'}`"></i>
                                    <span class="status">{{ (row.status)?'Active':'Inactive' }}</span>
                                </badge>
                            </template>
                        </el-table-column>

                        <el-table-column label="Actions" v-if="this.$store.getters.StateUser.user.role!=='staff'">
                            <template v-slot="{ row }">
                            <el-dropdown trigger="click" class="dropdown">
                                <span class="btn btn-sm btn-icon-only text-info">
                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
                                        <!--<a class="dropdown-item" href="#">Edit</a>-->
                                        <router-link :to="{name: 'member update', params: { id: row.id }}" class="dropdown-item">Edit</router-link>
                                        <span class="dropdown-item" @click="deleteMember(row.id)">Delete</span>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="card-footer py-4 d-flex justify-content-end">
                        <base-pagination @input="changePagination" :value="currentPage" :perPage="10" :total="totalRecords"></base-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import {
    ElTable,
    ElTableColumn,
    ElDropdownMenu,
    ElDropdownItem,
    ElDropdown,
} from "element-plus";
export default {
    name: "membersTable",
    components: {
        Loading,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElDropdown.name]: ElDropdown,
        [ElDropdownItem.name]: ElDropdownItem,
        [ElDropdownMenu.name]: ElDropdownMenu,
    },
    data() {
        return {
            members: [],
            currentPage: 1,
            totalRecords: 0,
            sortOrder:'',
            search:'',
            isLoading: false,
            errorType:'',
            errorMessage:'',
        };
    },
    beforeMount() {
        this.fetchMembers();
    },
    mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },3000);
    },
    methods: {
        searchFun() {
          this.fetchMembers();
        },
        async approveAccount(id) {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure you want to approve this member?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.approveAccountMain(id);
                }
            });
        },
        async approveAccountMain(id) {
            try {
                this.isLoading = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                const formData = {'id' : id};
                let res = await axios.post('approve-account',formData);
                this.isLoading = false;
                if(res) {
                    this.$swal({
                        title: 'Approved!',
                        text: 'Account Approved Successfully.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6'
                    })
                    this.fetchMembers();
                } else {
                    this.$swal({
                        title: 'Something Went Wrong!',
                        text: localStorage.getItem('fw_error_message'),
                        icon: 'error',
                        confirmButtonColor: '#3085d6'
                    });
                }
            } catch (error) {
                this.isLoading = false;
                this.$swal({
                    title: 'Something Went Wrong!',
                    text: localStorage.getItem('fw_error_message'),
                    icon: 'error',
                    confirmButtonColor: '#3085d6'
                });
            }
        },
        async saveNewPoint(id) {
            let updatedRow = this.members.findIndex((element)=>{
                return (element.id==id);
            });

            const formData = {
                'point' : this.members[updatedRow].point,
                'id' : id
            };

            try {
                this.isLoading = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                let res = await axios.post('update-point',formData);
                this.isLoading = false;
                
                if(res) {
                    this.errorType = 'success';
                    this.errorMessage = res.data.message;
                    this.fetchMembers();
                    setTimeout(()=>{
                        this.errorMessage = null;
                    },3000);
                } else {
                    this.errorType = 'danger';
                    this.errorMessage = localStorage.getItem('fw_error_message');
                    setTimeout(()=>{
                        this.errorMessage = null;
                    },3000);
                }  
            } catch (error) {
                this.isLoading = false;
                this.errorType = 'danger';
                this.errorMessage = localStorage.getItem('fw_error_message');
                setTimeout(()=>{
                    this.errorMessage = null;
                },3000);
            }

            // axios.post('update-point',formData).then(() => {
            //     this.fetchMembers();
            // });
        },
        onSortChange(evt){
            this.sortOrder = (evt.order==='ascending')?'ASC':'DESC';
            this.fetchMembers();
        },
        changePagination (value) {
            this.currentPage = value;
            this.fetchMembers();
        },
        increment(id){
            let updatedRow = this.members.findIndex((element)=>{
                return (element.id==id);
            });
            this.members[updatedRow].point = this.members[updatedRow].point+1;
        },
        decrement(id){
            let updatedRow = this.members.findIndex((element)=>{
                return (element.id==id);
            });
            this.members[updatedRow].point = this.members[updatedRow].point-1;
        },
        async deleteMember(id) {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure you want to delete this member?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteMemberMain(id);
                }
            });
        },
        async deleteMemberMain(id) {

            try {
                this.isLoading = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                let res = await axios.delete('users/'+id);
                this.isLoading = false;

                if(res) {
                    this.$swal({
                        title: 'Deleted!',
                        text: 'Memeber deleted successfully.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6'
                    })
                    this.fetchMembers();
                } else {
                    this.$swal({
                        title: 'Something Went Wrong!',
                        text: localStorage.getItem('fw_error_message'),
                        icon: 'error',
                        confirmButtonColor: '#3085d6'
                    });
                }
            } catch (error) {
                this.isLoading = false;
                this.$swal({
                    title: 'Something Went Wrong!',
                    text: localStorage.getItem('fw_error_message'),
                    icon: 'error',
                    confirmButtonColor: '#3085d6'
                });
            }
        },
        async fetchMembers() {
            try {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                axios.get('users?model=member&page='+this.currentPage+'&order='+this.sortOrder+'&search='+this.search+'&deletedAt=false').then((response) => {
                    this.totalRecords = response.data.data.count;
                    this.members = [];
                    for (var i = 0; i < response.data.data.rows.length; i++) {
                        response.data.data.rows[i].originalPoint = response.data.data.rows[i].point;
                        response.data.data.rows[i].createdAt = moment(String(response.data.data.rows[i].createdAt)).format('MM/DD/YYYY');
                        this.members.push(response.data.data.rows[i]);
                    }
                });
            } catch (error) {
                this.isLoading = false;
                this.errorType = 'danger';
                this.errorMessage = localStorage.getItem('fw_error_message');
                setTimeout(()=>{
                    this.errorMessage = null;
                },3000);
            }
        },
    }
};
</script>
<style>
    .el-table th>.cell {
        word-break: break-word;
    }
</style>