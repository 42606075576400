<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        //min-height: 600px;
        background-image: url(img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white" style="white-space: nowrap;"></h1>
          </div>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        
        

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Change Password</h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="createProfileData">
              
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      type="password"
                      label="Old Password"
                      placeholder="Old Password"
                      name="oldPassword"
                      id="oldPassword"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="oldPassword"
                      :error="(!v$.oldPassword.$error)?'':v$.oldPassword.$errors[0].$message"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      type="password"
                      label="New Password"
                      placeholder="New Password"
                      name="password"
                      id="password"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="password"
                      :error="(!v$.password.$error)?'':v$.password.$errors[0].$message"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      type="password"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="confirmPassword"
                      :error="(!v$.confirmPassword.$error)?'':v$.confirmPassword.$errors[0].$message"
                    />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Update</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "ChangePassword",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  validations () {
    return {
      oldPassword: { required: helpers.withMessage("Old password is required.",required), $lazy: true },
      password: { required: helpers.withMessage("New password is required.",required), pRegex: helpers.withMessage('Password must be at least 8 characters long and should contain uppercase and lowercase letters, special characters and numbers.', helpers.regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)), $lazy: true },
      confirmPassword: { required: helpers.withMessage("Confirm password is required.",required), sameAsPassword: helpers.withMessage("Password and confirm password does not match.", sameAs(this.password)), $lazy: true }

    }
  },
  data() {
    return {
        oldPassword:"",
        password: "",
        confirmPassword: "",
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
    };
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },100);
    },
  methods: {
    async createProfileData() {
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
      const data = {
        "oldPassword": this.oldPassword,
        "password": this.password,
      };

      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.post('change-password',data);
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            setTimeout(()=>{
                this.errorMessage = null;
                this.$router.push("/change-password");
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
};
</script>
<style></style>
