<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <base-alert v-if="errorMessage" :type="errorType">
        {{errorMessage}}
      </base-alert>
      <div class="card bg-secondary shadow border-0">


        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <img src="/img/brand/white.png" height="100" width="200"/>
          </div>
          
          <form v-on:submit.prevent="submitForm">
            <base-input
              formClasses="input-group-alternative mb-3"
              :required="true"
              placeholder="New Password"
              addon-left-icon="ni ni-lock-circle-open"
              name="password"
              type="password" 
              id="password"
              v-model="password"
              :valid="(!showError)?undefined:(!v$.password.$error)?true:false"
              :error="(!v$.password.$error)?'':v$.password.$errors[0].$message"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              :required="true"
              placeholder="Confirm Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              id="confirmPassword" 
              name="confirmPassword" 
              v-model="confirmPassword" 
              :valid="(!showError)?undefined:(!v$.confirmPassword.$error)?true:false"
              :error="(!this.v$.confirmPassword.$error)?'':this.v$.confirmPassword.$errors[0].$message"
            >
            </base-input>

            
            
            <div class="text-center">
              <base-button tag="button" nativeType="submit" type="info" class="my-4">Reset</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <router-link to="/login" class="text-light"><small>Login</small></router-link>
        <div class="col-6 text-right">
          <!-- <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs, helpers  } from '@vuelidate/validators'; //helpers, sameAs
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'ResetPassword',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  beforeMount() {
    this.userId = this.$route.params.id;
  },
  data () {
    return {
      userId: '',
      password: '',
      confirmPassword: '',
      show_error: false,
      isLoading: false,
      errorType:'',
      errorMessage:'',
    }
  },
  
  validations () {
    return {
      password: { required: helpers.withMessage("Password is required.",required), pRegex: helpers.withMessage('Password must be at least 8 characters long and should contain uppercase and lowercase letters, special characters and numbers.', helpers.regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)) },
      confirmPassword: { required: helpers.withMessage("Confirm password is required.",required), sameAsPassword: helpers.withMessage("Password and confirm password does not match.", sameAs(this.password)) } //sameAsPassword:sameAs('password')
    }
  },
  methods: {
    async submitForm () {

      this.showError = true;
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }

      try {
          this.isLoading = true;
          let res = await axios.post('reset-password',{userId:this.userId,password:this.password});
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            setTimeout(()=>{
                this.errorMessage = null;
                this.$router.push("/login");
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
}
</script>
<style></style>