/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import { store } from './store';
import axios from 'axios';
import { VuelidatePlugin } from '@vuelidate/core';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

axios.defaults.withCredentials = false;
axios.defaults.baseURL = 'https://api.futurewash.com/api/';
axios.defaults.headers.common['Content-Type'] = 'application/json';


axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    localStorage.setItem('fw_error_message', error.response.data.message);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = false;
      store.dispatch('LogOut')
      return router.push('/login')
    }
  }
});

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VuelidatePlugin);
appInstance.use(VueSweetalert2);
appInstance.mount("#app");