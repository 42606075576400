<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        //min-height: 600px;
        background-image: url(/img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white" style="white-space: nowrap;text-transform:capitalize;">{{privacyPolicyLink}} {{appointmentLink}}</h1>
            <p class="text-white mt-0 mb-5">
              {{note}}
            </p>
          </div>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Content Management</h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="updateContentData">
              <h6 class="heading-small text-muted mb-4">Miscellaneous Content</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Privacy Policy Link"
                      placeholder="Privacy Policy Link"
                      name="privacyPolicyLink"
                      id="privacyPolicyLink"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="privacyPolicyLink"
                      :value="privacyPolicyLink"
                      :error="(!v$.privacyPolicyLink.$error)?'':v$.privacyPolicyLink.$errors[0].$message"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Appointment Link"
                      placeholder="Appointment Link"
                      name="appointmentLink"
                      id="appointmentLink"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="appointmentLink"
                      :value="appointmentLink"
                      :error="(!v$.appointmentLink.$error)?'':v$.appointmentLink.$errors[0].$message"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Contact"
                      placeholder="Contact"
                      name="contact"
                      id="contact"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="contact"
                      :value="contact"
                      v-on:keyup="contactChange"
                      :error="(!v$.contact.$error)?'':v$.contact.$errors[0].$message"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Membership Points</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-4" v-for="(next, i) in pointList" :key="i">
                    <base-input
                      alternative=""
                      :label="'Point - '+next.value+' Info'"
                      :placeholder="'Point - '+next.value+' Info'"
                      :name="'pointList'+next.value"
                      :id="'pointList'+next.value"
                      :required="true"
                      input-classes="form-control-alternative"
                      v-model="pointList[i].label"
                      :value="next.label"
                      :error="(show_error && !pointList[i].label)?'This field is required.':''"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Member Benefit
                <span>
                    &nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-info btn-sm" @click="memBen('add')"><i class="ni ni-fat-add"></i></button>
                </span>
              </h6>

              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-4" v-for="(next, i) in memberBenefits" :key="i">
                    <div class="row">
                      <div class="col-md-10">
                        <base-input
                          alternative=""
                          placeholder="Member Benefit"
                          name="memberBenefitsTitle"
                          id="memberBenefitsTitle"
                          input-classes="form-control-alternative"
                          :required="true"
                          v-model="memberBenefits[i].title"
                          :value="next.title"
                          :error="(show_error && !memberBenefits[i].title)?'This field is required.':''"
                        />
                      </div>
                      <div class="col-md-2 pt-2">
                        <button type="button" class="btn btn-info btn-sm" @click="memBen('remove',i)"><i class="ni ni-fat-delete"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="my-4" />
              <!-- Description -->

              <h6 class="heading-small text-muted mb-4">Point Services
                <span>
                    &nbsp;&nbsp;&nbsp;<button type="button" class="btn btn-info btn-sm" @click="pointSer('add')"><i class="ni ni-fat-add"></i></button>
                </span>
              </h6>

              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12" v-for="(next, i) in pointServices" :key="i">
                    <div class="row">
                      <div class="col-md-10">
                        <base-input
                          alternative=""
                          placeholder="Title"
                          name="pointServicesTitle"
                          id="pointServicesTitle"
                          input-classes="form-control-alternative"
                          :required="true"
                          v-model="pointServices[i].title"
                          :value="next.title"
                          :error="(show_error && !pointServices[i].title)?'This field is required.':''"
                        />
                      </div>
                      <div class="col-md-2 pt-2">
                        <button type="button" class="btn btn-info btn-sm" @click="pointSerRow('add',i)"><i class="ni ni-fat-add"></i></button>
                        <button type="button" class="btn btn-info btn-sm" @click="pointSer('remove',i)"><i class="ni ni-fat-delete"></i></button>
                      </div>
                      <div class="col-md-12" v-for="(next1, j) in pointServices[i].rows" :key="j">
                        <div class="row">
                          <div class="col-md-5">
                            <base-input
                              alternative=""
                              placeholder="Title"
                              name="pointServicesTitle"
                              id="pointServicesTitle"
                              input-classes="form-control-alternative"
                              :required="true"
                              v-model="pointServices[i].rows[j].title"
                              :value="next1.title"
                              :error="(show_error && !pointServices[i].rows[j].title)?'This field is required.':''"
                            />
                          </div>
                          <div class="col-md-5">
                            <base-input
                              alternative=""
                              placeholder="Description"
                              name="pointServicesTitle"
                              id="pointServicesTitle"
                              input-classes="form-control-alternative"
                              :required="true"
                              v-model="pointServices[i].rows[j].description"
                              :value="next1.description"
                              :error="(show_error && !pointServices[i].rows[j].description)?'This field is required.':''"
                            />
                          </div>
                          <div class="col-md-2 pt-2">
                            <button type="button" class="btn btn-info btn-sm" @click="pointSerRow('remove',i,j)"><i class="ni ni-fat-delete"></i></button>
                          </div>
                        </div>
                      </div>
                      <hr class="my-6" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Submit</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "ContentManagement",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  validations () {
    return {
      privacyPolicyLink: { required: helpers.withMessage("First name is required.",required), $lazy: true }, 
      appointmentLink: { required: helpers.withMessage("Last name is required.",required), $lazy: true }, 
      contact: { required: helpers.withMessage("Phone number is required.",required), pRegex: helpers.withMessage('Enter valid 10 digit phone number.', helpers.regex(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)), $lazy: true }
    }
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
        },100);
        this.getMemberBenefitData();
    },
  data() {
    return {
        allData: {},
        pointList:[],
        privacyPolicyLink: "",
        appointmentLink: "",
        contact:"",
        memberBenefits:[],
        pointServices:[],
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
      
    };
  },
  methods: {
    async getMemberBenefitData() {
      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.get('miscellaneous-data',{});
          this.isLoading = false;
          if(res) {
            this.allData = res.data.data;
            this.privacyPolicyLink = this.allData.privacyPolicyUrl;
            this.appointmentLink = this.allData.appointmentLink;
            this.contact = this.allData.contact;
            this.pointList = this.allData.pointList;
            this.memberBenefits = this.allData.memberBenefit[1].data;
            this.pointServices = this.allData.memberBenefit[0].data;
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
    contactChange() {
      this.contact = this.contact.trim();
      let value = this.contact;
      value = value.replace(/-/g, '');
      if ((isNaN(value) || value.length > 10)) {
        this.contact = this.mobileNumber;
      } else {
        this.mobileNumber = this.contact.trim();
      }
      let text = this.contact;
      this.contact = text.replace(/-/g, '');
      if (this.contact.length > 3 && this.contact.length <= 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3);
      } else if (this.contact.length > 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3, 6) + '-' + this.contact.slice(6);
      }
    },
    memBen(action, index = null) {
      if(action === 'add') {
        this.memberBenefits.push({"title":""});
      } else {
        if(this.memberBenefits.length===1) {
          this.errorType = 'danger';
          this.errorMessage = "Atleast one data should be there.";
        } else {
          this.memberBenefits.splice(index,1);
        }
      }
    },
    pointSer(action, index = null) {
      if(action === 'add') {
        this.pointServices.push({"point":this.pointServices.length,"rows":[{"title":"","description":""}],"title":""});
      } else {
        if(this.pointServices.length===1) {
          this.errorType = 'danger';
          this.errorMessage = "Atleast one data should be there.";
        } else {
          this.pointServices.splice(index,1);
        }
      }
    },
    pointSerRow(action, parentIndex, rowIndex = null) {
      if(action === 'add') {
        this.pointServices[parentIndex].rows.push({"title":"","description":""});
      } else {
        if(this.pointServices[parentIndex].rows.length===1) {
          this.errorType = 'danger';
          this.errorMessage = "Atleast one data should be there.";
        } else {
          this.pointServices[parentIndex].rows.splice(rowIndex,1);
        }
      }
    },
    async updateContentData() {
    
      this.show_error = true;
      let err = false;
      let point = 0;
      this.pointServices.forEach((el)=>{
        this.pointServices[point].point = point+1;
        if(!el.title)
          err = true;
        el.rows.forEach((el1)=>{
          if(!el1.title || !el1.description)
            err = true;
        });
        point++;
      });

      this.memberBenefits.forEach((el)=>{
        if(!el.title)
          err = true;
      });

      this.pointList.forEach((el)=>{
        if(!el.label)
          err = true;
      });

      this.v$.$validate(); 
      if (this.v$.$error || err) {
        return;
      }

      const dataToUpdate = {
        "pointList":this.pointList,
        "memberBenefits":this.memberBenefits,
        "pointServices":this.pointServices,
        "privacyPolicyLink": this.privacyPolicyLink,
        "appointmentLink": this.appointmentLink,
        "membershipPoint": this.membershipPoint,
        "contact": this.contact
      };
      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.put('member-benefit',dataToUpdate);
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
      this.show_error = false;
    },
  }
};
</script>
<style></style>
