<template>
<div>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
        <div class="row">
            
            <div class="col">

                <div class="card shadow">
                    <div class="card-header border-0">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <base-alert v-if="errorMessage" :type="errorType">
                                    {{errorMessage}}
                                </base-alert>
                            </div>


                            <div class="col">
                                <h3 class="mb-0">
                                    Archived Member List
                                </h3>
                            </div>
                            <div class="col text-right">
                                
                            <input placeholder="Search" v-model="search" v-on:keyup="searchFun" width="200px" />
                                <!--<base-button type="info" size="sm">See all</base-button>-->
                            </div>
                        </div>
                    </div>

                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" @sort-change="onSortChange" :data="members" style="width: 100%">
                        
                        <el-table-column label="Member #"  prop="name">
                            <template v-slot="{ row }">
                            {{row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Point Member" >
                            <template v-slot="{ row }">
                            {{row.membershipPoint}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Full Name" >
                            <template v-slot="{ row }">
                            <span style="text-transform:capitalize;">{{row.firstName+' '+row.lastName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Email Address" min-width="100px">
                            <template v-slot="{ row }">
                            {{row.email}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Phone Number">
                            <template v-slot="{ row }">
                            {{row.contact}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Billable Amount" prop="budget">
                            <template v-slot="{ row }">
                            {{(row.billableAmount)?'$'+row.billableAmount:'-'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Date Joined">
                            <template v-slot="{ row }">
                            {{row.createdAt}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Wash At Home Subscriber">
                            <template v-slot="{ row }">
                            {{((row.washAtHome===null)?'-':(row.washAtHome)?'Yes':'No')}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Note">
                            <template v-slot="{ row }">
                            {{(row.note)?((row.note.length>30)?row.note.slice(0, 30)+'...':row.note):'-'}}
                            </template>
                        </el-table-column>

                        <el-table-column label="Actions">
                            <template v-slot="{ row }">
                            <el-dropdown trigger="click" class="dropdown">
                                <span class="btn btn-sm btn-icon-only text-info">
                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
                                        <span class="dropdown-item" @click="restoreMember(row.id)">Restore</span>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="card-footer py-4 d-flex justify-content-end">
                        <base-pagination @input="changePagination" :value="currentPage" :perPage="10" :total="totalRecords"></base-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import {
    ElTable,
    ElTableColumn,
    ElDropdownMenu,
    ElDropdownItem,
    ElDropdown,
} from "element-plus";
export default {
    name: "archivedMembersTable",
    components: {
        Loading,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElDropdown.name]: ElDropdown,
        [ElDropdownItem.name]: ElDropdownItem,
        [ElDropdownMenu.name]: ElDropdownMenu,
    },
    data() {
        return {
            members: [],
            currentPage: 1,
            totalRecords: 0,
            sortOrder:'',
            search:'',
            isLoading: false,
            errorType:'',
            errorMessage:'',
        };
    },
    beforeMount() {
        this.fetchMembers();
    },
    mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },3000);
    },
    methods: {
        searchFun() {
          this.fetchMembers();
        },
        onSortChange(evt){
            this.sortOrder = (evt.order==='ascending')?'ASC':'DESC';
            this.fetchMembers();
        },
        changePagination (value) {
            this.currentPage = value;
            this.fetchMembers();
        },
        async restoreMember(id) {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure you want to restore this member?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.restoreMemberMain(id);
                }
            });
        },
        async restoreMemberMain(id) {
            try {
                    this.isLoading = true;
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                    let res = await axios.post('restore-account',{id});
                    this.isLoading = false;

                    if(res) {
                        this.$swal({
                            title: 'Restored!',
                            text: res.data.message,
                            icon: 'success',
                            confirmButtonColor: '#3085d6'
                        })
                        this.fetchMembers();
                    } else {
                        this.$swal({
                            title: 'Something Went Wrong!',
                            text: localStorage.getItem('fw_error_message'),
                            icon: 'error',
                            confirmButtonColor: '#3085d6'
                        });
                    }  
            } catch (error) {
                this.$swal({
                            title: 'Something Went Wrong!',
                            text: localStorage.getItem('fw_error_message'),
                            icon: 'error',
                            confirmButtonColor: '#3085d6'
                        });
            }
        },
        async fetchMembers() {
            try {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
                axios.get('users?model=member&page='+this.currentPage+'&order='+this.sortOrder+'&search='+this.search+'&deletedAt=true').then((response) => {
                    this.totalRecords = response.data.data.count;
                    this.members = [];
                    for (var i = 0; i < response.data.data.rows.length; i++) {
                        response.data.data.rows[i].originalPoint = response.data.data.rows[i].point;
                        response.data.data.rows[i].createdAt = moment(String(response.data.data.rows[i].createdAt)).format('MM/DD/YYYY');
                        this.members.push(response.data.data.rows[i]);
                    }
                });
            } catch (error) {
                this.isLoading = false;
                this.errorType = 'danger';
                this.errorMessage = localStorage.getItem('fw_error_message');
                setTimeout(()=>{
                    this.errorMessage = null;
                },3000);
            }
        },
    }
};
</script>
<style>
    .el-table th>.cell {
        word-break: break-word;
    }
</style>