<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="FW"
      title="Future Wash"
    >
      <template v-slot:links>
        <!--<sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-info',
            path: '/dashboard',
          }"
        />-->

        <sidebar-item
          :link="{
            name: 'Members',
            icon: 'ni ni-bullet-list-67 text-info',
            path: '/members',
          }"
        />
        <sidebar-item v-if="!isStaff"
          :link="{
            name: 'Staff',
            icon: 'ni ni-badge text-info',
            path: '/staff',
          }"
        />
        <sidebar-item v-if="!isStaff"
          :link="{
            name: 'Announcement',
            icon: 'ni ni-notification-70 text-info',
            path: '/announcement-create',
          }"
        />
        <sidebar-item v-if="!isStaff"
          :link="{
            name: 'Content Management',
            icon: 'ni ni-bullet-list-67 text-info',
            path: '/content-management',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
//import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    //ContentFooter,
  },
  computed :{
    isStaff: function() {
        return (this.$store.getters.StateUser.user.role==='staff')?true:false;
    },
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
