<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        //min-height: 600px;
        background-image: url(img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white" style="white-space: nowrap;"></h1>
          </div>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Create Announcement</h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="createAnnouncementData">
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      type="text"
                      label="Title"
                      placeholder="Title"
                      name="title"
                      id="title"
                      :value="title"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="title"
                      :error="(!v$.title.$error)?'':v$.title.$errors[0].$message"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <base-input alternative="" label="Description" :required="true" v-model="text" :error="(!v$.text.$error)?'':v$.text.$errors[0].$message">
                      <textarea
                        rows="4"
                        class="form-control form-control-alternative"
                        placeholder="Description"
                        name="text"
                        id="text"
                        v-on:keyup="textValueChange($event)"
                      >
                      </textarea>
                  </base-input>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Create</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "AnnouncementCreate",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  validations () {
    return {
      title: { required: helpers.withMessage("Title is required.",required), $lazy: true },
      text: { required: helpers.withMessage("Description is required.",required), $lazy: true }
    }
  },
  data() {
    return {
        title:"",
        text: "",
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
    };
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },100);
    },
  methods: {
    async createAnnouncementData() {
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
      const data = {
        "title": this.title,
        "text": this.text,
      };

      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.post('send-announcment',data);
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            document.getElementById('title').value = "";
            document.getElementById('text').value = "";
            
            this.v$.$reset(); //this.v$.text.$reset();
            setTimeout(()=>{
                this.errorMessage = null;
                this.text=""; this.title = "";
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
    textValueChange(evt) {
      this.text = evt.target.value;
    },
  }
};
</script>
<style></style>
