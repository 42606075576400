<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        background-image: url(img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        

        <div class="col-xl-12 oStatusrder-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Staff Profile</h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="createProfileData">
              
              <div class="pl-lg-4">
                <div class="row" v-if="isSuperAdmin">
                  <div class="col-lg-6">
                    <base-input label="Level" :required="true"><br/>
                      <input type="radio" @click="roleChange($event)" name="role" :checked="role=='staff'" id="staff" value="staff" />&nbsp;&nbsp;&nbsp;<label for="staff">Staff</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" @click="roleChange($event)" name="role" :checked="role=='admin'" id="admin" value="admin" />&nbsp;&nbsp;&nbsp;<label for="admin">Admin</label>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="First name"
                      placeholder="First name"
                      name="firstName"
                      id="firstName"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="firstName"
                      :error="(!v$.firstName.$error)?'':v$.firstName.$errors[0].$message"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Last name"
                      placeholder="Last name"
                      name="lastName"
                      id="lastName"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="lastName"
                      :error="(!v$.lastName.$error)?'':v$.lastName.$errors[0].$message"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="contact"
                      id="contact"
                      input-classes="form-control-alternative"
                      v-on:keyup="contactChange"
                      :required="true"
                      v-model="contact"
                      :value="contact"
                      :error="(!v$.contact.$error)?'':v$.contact.$errors[0].$message"
                    />
                    
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Email address"
                      placeholder="jesse@example.com"
                      name="email"
                      id="email"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="email"
                      :error="(!v$.email.$error)?'':v$.email.$errors[0].$message"
                    />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Create</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "UserCreate",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  computed :{
    isAdmin: function() {
      return (this.$store.getters.StateUser.user.role==='admin')?true:false;
    },
    isSuperAdmin: function() {
        return (this.$store.getters.StateUser.user.role==='super-admin')?true:false;
    },
  },
  validations () {
    return {
      firstName: { required: helpers.withMessage("First name is required.",required), $lazy: true }, 
      lastName: { required: helpers.withMessage("Last name is required.",required), $lazy: true }, 
      contact: { required: helpers.withMessage("Phone number is required.",required), pRegex: helpers.withMessage('Enter valid 10 digit phone number.', helpers.regex(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)), $lazy: true }, 
      email: { required: helpers.withMessage("Email is required.",required), email: helpers.withMessage("Enter valid email address.",email), $lazy: true },
      role: {required: helpers.withMessage("Level is required.",required), $lazy: true },
    }
  },
  data() {
    return {
        
        role:"staff",
        firstName: "",
        lastName: "",
        email: "",
        contact:"",
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
    };
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },100);
    },
  methods: {
    contactChange() {
      // let text = evt.target.value;
      // let value = text.replace(/-/g, '');
      // if (value.length > 3 && value.length <= 6) {
      //   value = value.slice(0, 3) + '-' + value.slice(3);
      // } else if (value.length > 6) {
      //   value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
      // }
      // this.contact = value;
      this.contact = this.contact.trim();
      let value = this.contact;
      value = value.replace(/-/g, '');
      if ((isNaN(value) || value.length > 10)) {
        this.contact = this.mobileNumber;
      } else {
        this.mobileNumber = this.contact.trim();
      }
      let text = this.contact;
      this.contact = text.replace(/-/g, '');
      if (this.contact.length > 3 && this.contact.length <= 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3);
        //console.log(this.contact)
      } else if (this.contact.length > 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3, 6) + '-' + this.contact.slice(6);
      }
    },
    roleChange(evt) {
      this.role = evt.target.value;
    },
    async createProfileData() {
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
      const user = {
        "email": this.email,
        "firstName": this.firstName,
        "lastName": this.lastName,
        "role": this.role,
        "contact": this.contact,
      };

      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.post('users',user);
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            
            setTimeout(()=>{
                this.errorMessage = null;
                this.$router.push("/staff");
            },3000);
            
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
};
</script>
<style></style>
