import { createRouter, createWebHashHistory } from "vue-router"; //createWebHashHistory

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/UserProfile.vue";
import ChangePasword from "../views/ChangePassword.vue";
import MemberTable from "../views/MemberTable.vue";
import MemberCreate from "../views/CreateMember.vue";
import MemberEdit from "../views/EditMember.vue";
import UserTable from "../views/UserTable.vue";
import UserCreate from "../views/CreateUser.vue";
import UserEdit from "../views/EditUser.vue";
import AnnouncementCreate from "../views/AnnouncementCreate.vue";
import ContentManagement from "../views/ContentManagement.vue";
import ArchivedMembersTable from "../views/ArchivedMembersTable.vue";
import ImportMember from "../views/ImportMember.vue";

import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import { store } from '../store';

const routes = [
  {
    path: "/",
    redirect: "/members",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        meta: { requiresAuth: true },
      },
      {
        path: "/members",
        name: "members",
        components: { default: MemberTable },
        meta: { requiresAuth: true },
      },
      {
        path: "/archived-members",
        name: "archived members",
        components: { default: ArchivedMembersTable },
        meta: { requiresAuth: true },
      },
      {
        path: "/member-create",
        name: "member create",
        components: { default: MemberCreate },
        meta: { requiresAuth: true },
      },
      {
        path: "/member-update/:id",
        name: "member update",
        components: { default: MemberEdit },
        meta: { requiresAuth: true },
      },
      {
        path: "/import-member",
        name: "import member",
        components: { default: ImportMember },
        meta: { requiresAuth: true },
      },
      {
        path: "/staff",
        name: "staff",
        components: { default: UserTable },
        meta: { requiresAuth: true },
      },
      {
        path: "/staff-create",
        name: "staff create",
        components: { default: UserCreate },
        meta: { requiresAuth: true },
      },
      {
        path: "/staff-update/:id",
        name: "staff update",
        components: { default: UserEdit },
        meta: { requiresAuth: true },
      },
      {
        path: "/announcement-create",
        name: "announcement create",
        components: { default: AnnouncementCreate },
        meta: { requiresAuth: true },
      },
      {
        path: "/content-management",
        name: "content management",
        components: { default: ContentManagement },
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
        meta: { requiresAuth: true },
      },
      {
        path: "/change-password",
        name: "change password",
        components: { default: ChangePasword },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        meta: { guest: true },
      },
      {
        path: "/forgot-password",
        name: "forgot password",
        components: { default: ForgotPassword },
        meta: { guest: true },
      },
      {
        path: "/reset-password/:id",
        name: "reset password",
        components: { default: ResetPassword },
        meta: { guest: true },
      },
    ],
  },
];

const router = createRouter({
  //mode: 'history',
  //hashbang: false,
  history: createWebHashHistory(),
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/members");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;