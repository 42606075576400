<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        //min-height: 600px;
        background-image: url(img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white" style="white-space: nowrap;"></h1>
          </div>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        
        

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Import Members
                      <span v-if="this.$store.getters.StateUser.user.role==='super-admin'">
                          &nbsp;&nbsp;&nbsp;<a target="_blank" href="https://api.futurewash.com/csv/sample.csv" class="btn btn-info btn-sm">Download Sample CSV</a>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="importMemberCSV">
              
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      type="file"
                      label="CSV File"
                      placeholder="CSV File"
                      name="file"
                      id="file"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="file"
                      @change="onFileChange"
                      :error="(!v$.file.$error)?'':v$.file.$errors[0].$message"
                    />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Import</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
//const fileRule = helpers.regex('image', /.(csv)$/)
export default {
  name: "ImportMember",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  validations () {
    return {
      file: { required: helpers.withMessage("File is required.",required), pRegex: helpers.withMessage('Upload CSV file only.', helpers.regex(/.+(\.csv)$/)), $lazy: true },

    }
  },
  data() {
    return {
        file:"",
        uploadedFile:"",
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
    };
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
            else
                document.getElementsByClassName('footer')[0].classList.remove('fix-footer');
        },100);
    },
  methods: {
    onFileChange(e) {
      this.uploadedFile = e.target.files || e.dataTransfer.files;
    },
    async importMemberCSV() {
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
      if (!this.uploadedFile.length)
        return;
      
      const data = new FormData();
      data.append('file',this.uploadedFile[0]);

      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.post('import-members',data);
          this.isLoading = false;

          if(res) {
            this.errorType = (res.data.status==="success")?'success':'danger';
            this.errorMessage = res.data.message;
            setTimeout(()=>{
                this.errorMessage = null;
                if(res.data.status==="success")
                  this.$router.push("/members");
            },3000);
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
};
</script>
<style></style>
