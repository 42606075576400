<template>
<div class="main-content bg-default">
    <!-- Navbar -->
    <base-nav class="navbar-top navbar-horizontal navbar-dark" containerClasses="px-4 container" :showToggleButton="false" expand>
        <!-- <router-link slot="brand" class="navbar-brand" to="/">
            <img src="img/brand/white.png" />
        </router-link> -->

        <template v-slot="{ closeMenu }">
            <!-- Collapse header -->
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <router-link to="/">
                            <img src="img/brand/green.png" />
                        </router-link>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" @click="closeMenu" class="navbar-toggler" aria-label="Toggle sidenav">
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Navbar items -->

            
        </template>
    </base-nav>
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white">
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
    <footer class="py-5 py-lg-4">
        <div class="container">
            <div class="row align-items-center justify-content-xl-between">
                <div class="col-xl-12">
                    <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{ year }}
                        <a href="https://www.futurewash.com/" class="font-weight-bold ml-1" style="color:#ffffff" target="_blank">Future Wash</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    <!-- <footer class="py-9">
        <div class="container">
            <div class="row align-items-center justify-content-xl-between">
                <div class="col-xl-6">
                    <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{ year }}
                        <a href="https://www.futurewash.com/" class="font-weight-bold ml-1" style="color:#ffffff" target="_blank">Future Wash</a>
                    </div>
                </div>
                <div class="col-xl-6">
                    <ul class="
                nav nav-footer
                justify-content-center justify-content-xl-end
              ">
                        <li class="nav-item">
                            <a href="/privacy-policy.html" class="nav-link" target="_blank">Privacy Policy</a>
                        </li>

                        <li class="nav-item">
                            <a href="https://www.futurewash.com/appointment-location" class="nav-link" target="_blank">Book Appointment</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer> -->
</div>
</template>

<script>
export default {
    name: "auth-layout",
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("LogOut");
            this.$router.push("/login");
        },
    },
    data() {
        return {
            year: new Date().getFullYear(),
            showMenu: false,
        };
    },
};
</script>

<style scoped>
	.nav-link:hover {
		color: #ffffff;
	}
    .footer {
        bottom: 0;
        position: fixed;
        width: 100%;
    }
</style>
