import axios from 'axios';

const state = {
    user: null,
    token: null,
};
const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
    StateToken: state => state.token,
};
const actions = {
    async LogIn({commit}, User) {
        let loginData = await axios.post('login', {'email':User.get('email'),'password':User.get('password')})
        //if(loginData) {
            await commit('setUser', loginData)
        //}
        
    },
    async LogOut({commit}){
        let user = null;
        let local_data = JSON.parse(localStorage.getItem('vuex'));
        axios.defaults.headers.common['Authorization'] = 'Bearer '+local_data.auth.token;
        await axios.post('logout', {},);
        await commit('LogOut', user)
    },
};
const mutations = {
    setUser(state, data){
        state.user = data.data.data;
        state.token = data.data.token;
    },
    LogOut(state){
        state.user = null;
        state.token = null;
    },
};
export default {
  state,
  getters,
  actions,
  mutations
};
