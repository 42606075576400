<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        //min-height: 600px;
        background-image: url(/img/theme/team-4-800x800.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-info opacity-8"></span>
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white" style="white-space: nowrap;text-transform:capitalize;">{{firstName}} {{lastName}}</h1>
            <p class="text-white mt-0 mb-5">
              {{note}}
            </p>
          </div>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  
                    <img
                      src="/img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <!--<base-button size="sm" type="info" class="mr-4"
                  >Connect</base-button
                >
                <base-button size="sm" type="default" class="float-right"
                  >Message</base-button
                >-->
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">{{(point)?point:0}}</span>
                      <span class="description">Points</span>
                    </div>
                    <div>
                      <span class="heading">{{(membershipPoint)?membershipPoint:0}}</span>
                      <span class="description">Point Member</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  <span style="text-transform:capitalize;">{{(firstName && lastName)?firstName+' '+lastName:''}}</span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{(email && contact)?email+', '+contact:''}}
                </div>
                <div class="h5 mt-4" v-if="status!==''">
                  <i class="ni business_briefcase-24 mr-2"></i>Status
                </div>
                <div v-if="status!==''">
                  <i class="ni education_hat mr-2"></i>{{(status)?'Active':'Inactive'}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12">
                    <base-alert v-if="errorMessage" :type="errorType">
                      {{errorMessage}}
                    </base-alert>
                    <h3 class="mb-0">Member Profile</h3>
                  </div>
                </div>
              </div>
            </template>

            <form v-on:submit.prevent="createMemberData">
              <h6 class="heading-small text-muted mb-4">Member information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="First name"
                      placeholder="First name"
                      name="firstName"
                      id="firstName"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="firstName"
                      :value="firstName"
                      :error="(!v$.firstName.$error)?'':v$.firstName.$errors[0].$message"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Last name"
                      placeholder="Last name"
                      name="lastName"
                      id="lastName"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="lastName"
                      :value="lastName"
                      :error="(!v$.lastName.$error)?'':v$.lastName.$errors[0].$message"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="contact"
                      id="contact"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="contact"
                      :value="contact"
                      v-on:keyup="contactChange"
                      :error="(!v$.contact.$error)?'':v$.contact.$errors[0].$message"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Email address"
                      placeholder="jesse@example.com"
                      name="email"
                      id="email"
                      input-classes="form-control-alternative"
                      :required="true"
                      v-model="email"
                      :value="email"
                      :error="(!v$.email.$error)?'':v$.email.$errors[0].$message"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Other information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Status" :required="true">
                      <select class="form-control" @change="statusValChange($event)" name="status" id="status" v-model="status" :value="status">
                        <option :selected="status==1" value="1">Active</option>
                        <option :selected="status==0" value="0">Inactive</option>
                      </select>
                     </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <base-input label="Point Member" :required="true">
                      <select class="form-control" @change="memPointValChange($event)" name="membershipPoint" id="membershipPoint" v-model="membershipPoint" :value="membershipPoint">
                        <option v-for="(next, i) in pointList" :key="i" :selected="membershipPoint===next.value" :value="next.value">{{next.label}}</option>
                      </select>
                     </base-input>
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Billable Amount ($)"
                      placeholder="Billable Amount"
                      name="billableAmount"
                      id="billableAmount"
                      input-classes="form-control-alternative"
                      v-model="billableAmount"
                      :value="billableAmount"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input label="Wash At Home Subscriber">
                      <select class="form-control" @change="washAtHomeValChange($event)" name="washAtHome" id="washAtHome" v-model="washAtHome" :value="washAtHome">
                        
                        <option :selected="washAtHome==1" value="1">Yes</option>
                        <option :selected="washAtHome==0" value="0">No</option>
                      </select>
                     </base-input>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Note</h6>
              <div class="pl-lg-4">
                <div class="form-group">
                  <base-input alternative="" label="Note">
                    <textarea
                      rows="4"
                      class="form-control form-control-alternative"
                      placeholder="Note"
                      name="note"
                      id="note"
                      v-model="note"
                      v-on:keyup="noteValueChange($event)"
                    >
                    </textarea
                    >
                  </base-input>
                </div>
              </div>

              <div class="text-right">
                <base-button tag="button" nativeType="submit" type="info" class="my-4">Submit</base-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "MemberCreate",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading
  },
  beforeMount() {
    this.getMiscData();
  },
  validations () {
    return {
      firstName: { required: helpers.withMessage("First name is required.",required), $lazy: true }, 
      lastName: { required: helpers.withMessage("Last name is required.",required), $lazy: true }, 
      contact: { required: helpers.withMessage("Phone number is required.",required), pRegex: helpers.withMessage('Enter valid 10 digit phone number.', helpers.regex(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)), $lazy: true }, 
      email: { required: helpers.withMessage("Email is required.",required), email: helpers.withMessage("Enter valid email address.",email), $lazy: true },
      status: { required: helpers.withMessage("Status is required.",required), $lazy: true },
      membershipPoint: { required: helpers.withMessage("Point member is required.",required), $lazy: true }, 
    }
  },
  mounted(){
        setTimeout(()=>{
            if(document.getElementsByTagName('body')[0].scrollHeight<window.screen.height)
                document.getElementsByClassName('footer')[0].classList.add('fix-footer');
        },100);
    },
  data() {
    return {
        code:"",
        role:"member",
        firstName: "",
        lastName: "",
        email: "",
        contact:"",
        membershipPoint: "",
        billableAmount: "",
        washAtHome: "",
        note: "",
        membershipCancelNote: "",
        point: "",
        status: "",
        show_error: false,
        isLoading: false,
        errorType:'',
        errorMessage:'',
        pointList:[]
      
    };
  },
  methods: {
    async getMiscData() {
      try {
        this.isLoading = true;
        axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
        let res = await axios.get('miscellaneous-data',{});
        this.isLoading = false;
        if(res) {
          this.pointList = res.data.data.pointList;
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
        } else {
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
        }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
    contactChange() {
      this.contact = this.contact.trim();
      let value = this.contact;
      value = value.replace(/-/g, '');
      if ((isNaN(value) || value.length > 10)) {
        this.contact = this.mobileNumber;
      } else {
        this.mobileNumber = this.contact.trim();
      }
      let text = this.contact;
      this.contact = text.replace(/-/g, '');
      if (this.contact.length > 3 && this.contact.length <= 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3);
        //console.log(this.contact)
      } else if (this.contact.length > 6) {
        this.contact = this.contact.slice(0, 3) + '-' + this.contact.slice(3, 6) + '-' + this.contact.slice(6);
      }
      // let text = evt.target.value;
      // let value = text.replace(/-/g, '');
      // if (value.length > 3 && value.length <= 6) {
      //   value = value.slice(0, 3) + '-' + value.slice(3);
      // } else if (value.length > 6) {
      //   value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
      // }
      // this.contact = value;
    },
    statusValChange(evt) {
      this.status = evt.target.value;
    },
    memPointValChange(evt) {
      this.membershipPoint = evt.target.value;
    },
    washAtHomeValChange(evt) {
      this.washAtHome = evt.target.value;
    },
    noteValueChange(evt) {
      this.note = evt.target.value;
    },
    async createMemberData() {
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }

      const member = {
        "email": this.email,
        "firstName": this.firstName,
        "lastName": this.lastName,
        "role": this.role,
        "membershipPoint": this.membershipPoint,
        "billableAmount": this.billableAmount,
        "washAtHome": (this.washAtHome===null)?null:((this.washAtHome==0)?false:true),
        "note": this.note,
        "status": (this.status==0)?false:true,
        "point": this.point,
        "code": this.code,
        "contact": this.contact
      };
      try {
          this.isLoading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.getters.StateToken;
          let res = await axios.post('users',member);
          this.isLoading = false;

          if(res) {
            this.errorType = 'success';
            this.errorMessage = res.data.message;
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
            this.$router.push("/members");
          } else {
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
          }  
      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
};
</script>
<style></style>
