// import Vue from 'vue';
// import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';

// // Load Vuex
// Vue.craete(Vuex);
// // Create store
// export default new Vuex.Store({
//   modules: {
//     auth
//   },
//   plugins: [createPersistedState()]
// });

import { createStore } from 'vuex'

export const store = createStore({
  state () {
    return {
      count: 1
    }
  },
  modules: {
    auth,
  },
  plugins: [createPersistedState()]
});