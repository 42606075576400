<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div class="row justify-content-center">

    <div class="col-lg-5 col-md-7">
    <base-alert v-if="errorMessage" :type="errorType">
      {{errorMessage}}
    </base-alert>
      <div class="card bg-secondary shadow border-0">

        
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <img src="img/brand/white.png" height="100" width="200"/>
          </div>
          
          <form v-on:submit.prevent="submitForm">
            <base-input
              formClasses="input-group-alternative mb-3"
              :required="true"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              name="email"
              type="email" 
              id="email"
              v-model="email"
              :valid="(!showError)?undefined:(!v$.email.$error)?true:false"
              :error="(!v$.email.$error)?'':v$.email.$errors[0].$message"
            >
            </base-input>

            <base-input
              v-if="show_otp"
              formClasses="input-group-alternative mb-3"
              :required="true"
              placeholder="OTP"
              type="text"
              addon-left-icon="ni ni-lock-circle-open"
              id="otp" 
              name="otp" 
              v-model="otp" 
              :valid="(!showError)?undefined:(!v$.otp.$error)?true:false"
              :error="(!v$.otp.$error)?'':v$.otp.$errors[0].$message"
            />
            
            

            
            <div class="text-center">
              <button v-if="!show_otp" type="button" @click="getOTP" class="btn btn-info">Get OTP</button>
              <base-button tag="button" v-if="show_otp" nativeType="submit" type="info" class="my-4">Submit</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="javascript:void(0)" @click="resendOtp" v-if="show_otp" class="text-light"><small>Resend OTP</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light"><small>Login</small></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {

  name:"ForgotPassword",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Loading,
  },
  
  data () {
    return {
      email: '',
      otp: '',
      userId: '',
      show_error: false,
      show_otp:false,
      isLoading: false,
      errorType:'',
      errorMessage:'',
    }
  },
  
  validations () {
    return {
      otp: { required: helpers.withMessage("OTP is required.",required), $lazy: true },
      email: { required: helpers.withMessage("Email is required.",required), email, $lazy: true }
    }
  },
  methods: {
    
    async getOTP() {
      this.v$.email.$touch();
      if (this.v$.email.$invalid) {
          return;
      } else {
        try {
            this.isLoading = true;
            let res = await axios.post('forgot-password',{email:this.email});
            this.isLoading = false;

            if(res) {
              this.show_otp = true;
              this.errorType = 'success';
              this.errorMessage = res.data.message;
              setTimeout(()=>{
                  this.errorMessage = null;
              },3000);
              this.userId = res.data.data.userId;
            } else {
              this.errorType = 'danger';
              this.errorMessage = localStorage.getItem('fw_error_message');
              setTimeout(()=>{
                  this.errorMessage = null;
              },3000);
            }  
            
        } catch (error) {
            this.isLoading = false;
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
        }
      }
    },
    async resendOtp() {
      this.v$.email.$touch();
      if (this.v$.email.$invalid) {
          return;
      } else {
        try {
            this.isLoading = true;
            let res = await axios.post('resend-otp',{email:this.email,type:'forgot-password'});
            this.isLoading = false;

            if(res) {
              this.errorType = 'success';
              this.errorMessage = res.data.message;
              setTimeout(()=>{
                  this.errorMessage = null;
              },3000);
              this.userId = res.data.data.userId;
            } else {
              this.errorType = 'danger';
              this.errorMessage = localStorage.getItem('fw_error_message');
              setTimeout(()=>{
                  this.errorMessage = null;
              },3000);
            }  
            
        } catch (error) {
            this.isLoading = false;
            this.errorType = 'danger';
            this.errorMessage = localStorage.getItem('fw_error_message');
            setTimeout(()=>{
                this.errorMessage = null;
            },3000);
        }
      }
    },
    async submitForm () {

      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
        
      try {
          this.isLoading = true;
          let res = await axios.post('verify-otp',{userId:this.userId,otp:this.otp,type:'forgot-password'});
          this.isLoading = false;

          if(res) {
              this.errorType = 'success';
              this.errorMessage = res.data.message;
              setTimeout(()=>{
                  this.errorMessage = null;
                  this.$router.push("/reset-password/"+res.data.data.userId);
              },3000);
              this.userId = res.data.data.userId;
            } else {
              this.errorType = 'danger';
              this.errorMessage = localStorage.getItem('fw_error_message');
              setTimeout(()=>{
                  this.errorMessage = null;
              },3000);
            }  

      } catch (error) {
          this.isLoading = false;
          this.errorType = 'danger';
          this.errorMessage = localStorage.getItem('fw_error_message');
          setTimeout(()=>{
              this.errorMessage = null;
          },3000);
      }
    },
  }
}
</script>
<style></style>
