<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <base-alert v-if="errorMessage" :type="errorType">
        {{errorMessage}}
      </base-alert>
      <div class="card bg-secondary shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <img src="img/brand/white.png" height="100" width="200"/>
          </div>
          
          <form v-on:submit.prevent="submitForm">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              name="email"
              type="email" 
              id="email"
              v-model="email"
              :required="true"
              :valid="(!showError)?undefined:(!v$.email.$error)?true:false"
              :error="(!v$.email.$error)?'':v$.email.$errors[0].$message"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-errorType3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              id="password" 
              name="password" 
              v-model="password" 
              :required="true"
              :valid="(!showError)?undefined:(!v$.password.$error)?true:false"
              :error="(!v$.password.$error)?'':v$.password.$errors[0].$message"
            >
            </base-input>

            
            <!-- <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
              <div class="error-msg">{{ errosubmitFormr.$message }}</div>
            </div> -->

            <!-- <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox> -->
            <div class="text-center">
              <base-button tag="button" nativeType="submit" type="info" class="my-4">Sign in</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/forgot-password" class="text-light"><small>Forgot password?</small></router-link>
        </div>
        <div class="col-6 text-right">
          <!-- <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          > -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import { mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
      Loading
  },
  data () {
    return {
      email: '',
      password: '',
      show_error: false,
      isLoading: false,
      errorType:'',
      errorMessage:'',
    }
  },
  
  validations () {
    return {
      password: { required: helpers.withMessage("Password is required.",required), $lazy: true },
      email: { required: helpers.withMessage("Email is required.",required), email, $lazy: true }
    }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submitForm () {
      
      this.showError = true;
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }
      
      const User = new FormData();
      User.append("email", this.email);
      User.append("password", this.password);
      try {
          this.isLoading = true;
          await this.LogIn(User);
          this.isLoading = false;
          this.$router.push("/members");
      } catch (error) {
        this.isLoading = false;
        this.errorType = 'danger';
        this.errorMessage = localStorage.getItem('fw_error_message');
        setTimeout(()=>{
            this.errorMessage = null;
        },3000);
      }
    },
  }
}
</script>
<style>
</style>
